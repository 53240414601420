/* global TrelloPowerUp */
import { h } from "preact";
import "../../style/power-up.min.css?global";
import style from "./style.css";

/**
 * An object containing methods to control the emotional reporting schema currently set.
 * @returns JSX rendering of the schema_modal page.
 */
const SchemaModal = () => {
  var t = window.TrelloPowerUp.iframe();                // Trello powerup call (having this within the object allows us to use this within a secondary iframe).


  /**
   * The method to be run when the save button is pressed.
   */
  const saveSchema = async () => {
    if (confirm("Previous schema data will be deleted.")) {
      t.set('board', 'shared', 'emotionSchema', currentSchema)
        .then(() => {
          t.closeModal();
          t.alert({message: "Successfully updated schema."});  
        });
    }
    else {
      alert("Mission aborted.");
    }
  };

  const closeModal = () => {
    t.closeModal();
  }

  return (
    <div class={style.container}>    
      <div class={style.textContainer}>
      The Emotimonitor team is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.
      <br /><br />
      Only an administrator of the board can view the emotional response data associated to a card.
      This data is de-identified, meaning that the administrator will not know who submitted which emotional responses.
      Once you have logged an emotional response, you are able to edit and remove it from the manager's view by simply reverting the scale back to '0' and then saving your response.
      </div>          
      <div class={style.emojiRow}>     
        <button class={style.saveButton} type="button" onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default SchemaModal;