import happySvg from "./assets/emoji/happy.svg";
import angerSvg from "./assets/emoji/anger.svg";
import sadSvg from "./assets/emoji/sad.svg";
import relaxedSvg from "./assets/emoji/relaxed.svg";
import boredSvg from "./assets/emoji/bored.svg";
import satisfiedSvg from "./assets/emoji/satisfied.svg";
import discouragedSvg from "./assets/emoji/discouraged.svg";
import fatiguedSvg from "./assets/emoji/fatigued.svg";
import anxietySvg from "./assets/emoji/anxiety.svg";
import enthusiasticSvg from "./assets/emoji/enthusiastic.svg"

import desireSvg from "./assets/emoji/desire.svg"
import disgustSvg from "./assets/emoji/disgust.svg"
import fearSvg from "./assets/emoji/fear.svg"

// Emoji SVG's are taken from twemoji.
// Source: https://github.com/twitter/twemoji

/*
export const emotions = {
  happy: {
    emoji: "😆",
    imgSrc: happySvg,
    enabled: true,
  },
  desire: {
    emoji: "🤤",
    imgSrc: desireSvg,
    enabled: true,
  },
  relaxed: {
    emoji: "🙂",
    imgSrc: relaxedSvg,
    enabled: true,
  },
  fear: {
    emoji: "😨",
    imgSrc: fearSvg,
    enabled: true,
  },
  anxiety: {
    emoji: "😟",
    imgSrc: anxietySvg,
    enabled: true,
  },
  sad: {
    emoji: "😭",
    imgSrc: sadSvg,
    enabled: true,
  },
  anger: {
    emoji: "😠",
    imgSrc: angerSvg,
    enabled: true,
  },
  disgust: {
    emoji: "🤮",
    imgSrc: disgustSvg,
    enabled: true,
  },
};
*/

export const emotions = {
  happy: {
    emoji: "😊",
    imgSrc: happySvg,
    enabled: true,
  },
  satisfied: {
    emoji: "😄",
    imgSrc: satisfiedSvg,
    enabled: true,
  },
  enthusiastic: {
    emoji: "🤩",
    imgSrc: enthusiasticSvg,
    enabled: true,
  },
  relaxed: {
    emoji: "🙂",
    imgSrc: relaxedSvg,
    enabled: true,
  },
  anxiety: {
    emoji: "😟",
    imgSrc: anxietySvg,
    enabled: true,
  },
  discouraged: {
    emoji: "😟",
    imgSrc: discouragedSvg,
    enabled: true,
  },
  bored: {
    emoji: "🥱",
    imgSrc: boredSvg,
    enabled: true,
  },
  fatigued: {
    emoji: "😴",
    imgSrc: fatiguedSvg,
    enabled: true,
  },
  anger: {
    emoji: "😠",
    imgSrc: angerSvg,
    enabled: true,
  },
  sad: {
    emoji: "😭",
    imgSrc: sadSvg,
    enabled: true,
  },
  desire: {
    emoji: "🤤",
    imgSrc: desireSvg,
    enabled: false,
  },
  disgust: {
    emoji: "🤮",
    imgSrc: disgustSvg,
    enabled: false,
  },
  fear: {
    emoji: "😨",
    imgSrc: fearSvg,
    enabled: false,
  },
};

// Emotion name => emoji
export const emotionEmojis = Object.freeze(
  Object.entries(emotions).reduce(
    (acc, [emoName, { emoji }]) => ({ ...acc, [emoName]: emoji }),
    {}
  )
);

// Initalise with emotion name => 1
export const initEmotionDict = () =>
  Object.keys(emotions).reduce(
    (acc, emoName) => ({ ...acc, [emoName]: 0 }),
    {}
  );

export const emotionNames = Object.freeze(Object.keys(emotions));
