/* global TrelloPowerUp */
import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import "../../style/power-up.min.css?global";
import style from "./style.css";
import { emotions } from "../../emotions.js";

/**
 * An object containing methods to control the emotional reporting schema currently set.
 * @returns JSX rendering of the schema_modal page.
 */
const SchemaModal = () => {
  var t = window.TrelloPowerUp.iframe();                // Trello powerup call (having this within the object allows us to use this within a secondary iframe).
  const [currentSchema, setCurrentSchema] = useState(emotions);           // Placeholder used whilst the iframe is active, maintains the set of emotions enabled state.
  const [loaded, setLoaded] = useState(false);          // State for whether or not the current schema is loaded yet.

  /**
   * The method to be run when the save button is pressed.
   */
  const saveSchema = async () => {
    if (confirm("Previous schema data will be deleted.")) {
      t.set('board', 'shared', 'emotionSchema', currentSchema)
        .then(() => {
          t.closeModal();
          t.alert({message: "Successfully updated schema."});  
        });
    }
    else {
      alert("Mission aborted.");
    }
  };

  /**
   * Retrieves the currently implemented schema object.
   */
  const fetchCurrentSchema = async () => {
    // Get data from trello which is shared and on the board level.
    const boardData = await t.get('board', 'shared')
    .then(function (data) {
      return data;
    });

    await cleanUndefinedData();

    console.log(`All shared board data ${JSON.stringify(boardData)}`);
    var emotionSchema = boardData.emotionSchema;

    // Check if a schema exists (i.e. if emotionSchema is undefined).
    if (emotionSchema == null) {
      // This could be the case if hosted locally or if the schema hasn't been set yet.
      t.set('board', 'shared', 'emotionSchema', emotions);
      console.log("The emotion schema has never been set.\nUsing defaults.");
    }
    else {
      console.log("The data has been previously set.");
      //console.log(emotionSchema);
    }

    // If the schema has been set prior, use that data, else, use the default schema.
    setCurrentSchema((!(emotionSchema == null)) ? emotionSchema : emotions);
    setLoaded(true);
  };

  /**
   * Provided a string key corresponding to an entry in the emotion object, 
   * toggles that emotion's 'enabled' boolean value.
   * 
   * @param {string} emoName -The key for the emotion which is to be updated.
   */
  const updateEmojiStatus = (emoName) => {
    console.log("Updating: " + emoName);
    currentSchema[emoName].enabled = !currentSchema[emoName].enabled;
  };

  /**
   * Sets all of the enabled values in the current schema according to the enabled
   * values within the imported 'emotions'.
   * @returns 
   */
  const setDefaultSchema = () => {
    if (confirm("Previous schema data will be deleted.")) {
      t.set('board', 'shared', 'emotionSchema', emotions)
        .then(() => {
          t.closeModal();
          t.alert({message: "Successfully updated schema."});  
        });
    }
    else {
      alert("Mission aborted.");
    }
  };

  /**
   * A helper function used to remove anything under the key 'undefined'.
   */
   const cleanUndefinedData = async () => {
    return t.remove('board', 'shared', 'undefined');
  };

  /**
   * Called when the iframe is loaded (is this like componentDidMount?).
   */
  useEffect(() => {
    if (t.isMemberSignedIn()) {
      fetchCurrentSchema().finally(() => {
        setLoaded(true);
      });
    }
    else {
      console.log("Not logged in, setting the default schema values.")
      setCurrentSchema(emotions);
      setLoaded(true);
    }   
  }, []);

  return (
    <div class={style.container}>
         
        {/* For each emotion in the current emotion object. */}
        {!loaded ? (
          <div class={style.paddingTop}>Fetching currently applied schema...</div>
          ) : (
            <div class={style.emojiGrid}>  
            {console.log(currentSchema)}
            {Object.keys(currentSchema).map((emoName) => (
                <div class={style.emojiRow}>       

                  <img src={currentSchema[emoName].imgSrc} />     
                  <span class={style.sentenceCase}>{emoName}</span>
                  <label class={style.toggleControl}>
                    <input type="checkbox" id={emoName + '_input'} checked={currentSchema[emoName].enabled ? "checked" : ""} onChange={() => updateEmojiStatus(emoName)}/>
                    <span class={style.control}></span>
                  </label>

                </div>
            ))}
            </div>
        )
      }
              
      <div class={style.emojiRow}>     
        {/*<button class={style.saveButton} type="button" onClick={fetchCurrentSchema}>Fetch Current Schema</button>*/}
        <button class={style.saveButton} type="button" onClick={setDefaultSchema}>Enable Default Schema</button>
        <button class={style.saveButton} type="button" onClick={saveSchema}>Save</button>
      </div>
    </div>
  );
};

export default SchemaModal;