import { h } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Init from '../routes/init';
import AddEmojiMenu from "../routes/add_emoji_menu";
import EmojiStats from "../routes/emoji_stats";
import Authorize from "../routes/authorize";
import EmotionSummary from '../routes/emotion_summary';
import Settings from '../routes/settings';
import SchemaModal from '../routes/settings/schema_modal.js';
import Options from '../routes/options';
import PrivacyStatementModal from '../routes/options/privacy_statement_modal.js';

const App = () => (
	<div id="app">
		<Router>
			<Init path="/" />
      		<AddEmojiMenu path="/add_emoji_menu" />
      		<EmojiStats path="/emoji_stats" />
			<Authorize path="/authorize" />
			<EmotionSummary path="/emotion_summary" />
			<Settings path="/settings" />
			<SchemaModal path="/settings/schema_modal" />
			<Options path="/options" />
			<PrivacyStatementModal path="/options/privacy_statement_modal" />
		</Router>
	</div>
)

export default App;
